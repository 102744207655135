import { z } from '@/config/zod.config'

export const SettingsSchema = z.object({
  appTitle: z.string().nullable(),
  documentDisclaimer: z.string().nullable(),
  logoDataUrl: z.string().nullable(),
  lockedFields: z.array(z.string()).nullable(),
  flags: z.array(z.string()).nullable(),
})

export type SettingsType = z.infer<typeof SettingsSchema>
