import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  Collaborator,
  CollaboratorRequest,
  CollaboratorSchema,
  CollaboratorsResponse,
  EmployeeHistoryResponseType,
  FollowUpListSchema,
  IPagination,
  InactivateRequestType,
} from '@/api'
import { apiClient } from '@/api/client'
import { ErrorResponseDto } from '@/api/error-response.dto'
import { OnExportData } from '@/components/ExportFiles'
import { useNotification } from '@/hooks/useNotification'
import { queryClient } from '@/query-client'
import { downloadBlob } from '@/shared/utils/download-blob.utils'

const getCollaborators = async (
  pagination: IPagination,
  filters: Filters
): Promise<Collaborator[]> => {
  const params = {
    ...filters,
    ...pagination,
    search: filters.search ?? undefined,
  }
  const { data } = await apiClient.post<Collaborator[]>(
    `/collaborators`,
    params
  )
  return CollaboratorsResponse.parse(data)
}

export const useCollaborators = (filters: Filters, pagination: IPagination) => {
  return useQuery({
    queryKey: ['collaborators', { pagination, filters }],
    queryFn: () => getCollaborators(pagination, filters),
  })
}

export const getCollaborator = async (id: string) => {
  const request = await apiClient.get(`/collaborators/${id}`)
  return CollaboratorSchema.parse(request.data)
}

export interface UseCollaboratorParams {
  enabled?: boolean
}

interface Filters {
  search?: string
  nationalityIds?: string[]
  clientIds?: string[]
  departmentIds?: string[]
  roleIds?: string[]
  countryIds?: string[]
  officeIds?: string[]
  managementOfficeIds?: string[]
  teamIds?: string[]
  contractIds?: string[]
  businessManagerIds?: string[]
  businessUnitManagerIds?: string[]
  peopleOfficerIds?: string[]
  genders?: string[]
  status?: string[]
  financialStatusIds?: string[]
  leavingReasonIds?: string[]
  billing?: string[]
  language?: string[]
  tenantIds?: string[]
  iefp?: boolean
  contractEndDate?: {
    startDate: Date | null
    endDate: Date | null
  } | null
}

type ExportUsers = Filters & OnExportData

const exportUsersByFileType = async (params: ExportUsers) => {
  const { data } = await apiClient.post<Blob>('/collaborators/export', params, {
    responseType: 'blob',
  })

  return downloadBlob(data, `collaborators.${params.type}`)
}

export const useExportUsers = () =>
  useMutation({
    mutationKey: ['export_users'],
    mutationFn: exportUsersByFileType,
  })

const handleInactive = async ({
  collaboratorId,
  ...data
}: InactivateRequestType) => {
  return await apiClient.put<Collaborator>(
    `/collaborators/${collaboratorId}/inactivate`,
    data
  )
}

export const useInactivate = () =>
  useMutation({
    mutationFn: handleInactive,
  })

const createCollaborator = async (data: CollaboratorRequest) => {
  const response = await apiClient.post<Collaborator>(
    '/collaborators/create',
    data
  )
  return response.data
}

const updateCollaborator = async ({ id, ...data }: CollaboratorRequest) => {
  const response = await apiClient.put<void>(`/collaborators/${id}`, data)
  return response.data
}

export const useMutateCollaborator = () => {
  const notification = useNotification()
  const { t } = useTranslation()

  return useMutation({
    mutationKey: ['collaborator'],
    mutationFn: async (data: CollaboratorRequest) => {
      if (data.id) return await updateCollaborator(data)
      else return await createCollaborator(data)
    },
    onSuccess: () => {
      notification.addNotification({
        type: 'success',
        message: t('messages.collaborator_saved'),
      })
      void queryClient.invalidateQueries({
        queryKey: ['collaborators'],
        exact: false,
      })
      void queryClient.invalidateQueries({
        queryKey: ['collaborator'],
        exact: false,
      })
    },
    onError: (error: Error | AxiosError) => {
      if (error instanceof AxiosError) {
        const data = (error as AxiosError<ErrorResponseDto>).response?.data
        if (data?.message)
          if (Array.isArray(data.message))
            for (const message of data.message) {
              notification.addNotification({
                type: 'error',
                message,
              })
            }
          else {
            notification.addNotification({
              type: 'error',
              message: data.message,
            })
          }
      }
    },
  })
}

const getHistory = async (employeeId: string) => {
  const response = await apiClient.get<EmployeeHistoryResponseType>(
    `/collaborators/${employeeId}/timeline`
  )
  return response.data
}

export const useEmployeeHistory = (employeeId: string) => {
  return useQuery({
    queryKey: ['employee_history', employeeId],
    queryFn: () => getHistory(employeeId),
  })
}

const getFollowUps = async (id: string) => {
  const response = await apiClient.get(`/collaborators/${id}/follow-ups`)
  return FollowUpListSchema.parse(response.data)
}

export const useFollowUps = (id: string) => {
  return useQuery({
    queryKey: ['follow-up', id],
    queryFn: () => getFollowUps(id),
  })
}

const addFollowUp = async ({
  id,
  ...data
}: {
  id: string
  content: string
}) => {
  await apiClient.post(`/collaborators/${id}/follow-ups`, data)
}

export const useAddFollowUp = () =>
  useMutation({
    mutationKey: ['follow-up'],
    mutationFn: addFollowUp,
  })
