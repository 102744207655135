import { useCallback } from 'react'
import { useRouteLoaderData } from 'react-router-dom'

import { apiClient } from '@/api/client'
import { SettingsSchema, SettingsType } from '@/api/settings/types'

export const getSettings = async () => {
  const request = await apiClient.get('/settings')
  return SettingsSchema.parse(request.data)
}

export const useAppSettings = () => {
  return useRouteLoaderData('settings') as SettingsType
}

export const useFieldLocks = () => {
  const { lockedFields } = useAppSettings()
  return useCallback(
    (fieldName: string) => {
      return !lockedFields || lockedFields?.includes(fieldName)
    },
    [lockedFields]
  )
}

export const useFieldLock = (fieldName: string) => {
  const hasLock = useFieldLocks()
  return hasLock(fieldName)
}

export const useFlags = () => {
  const { flags } = useAppSettings()
  return useCallback(
    (flag: string) => {
      return !flags || flags.includes(flag)
    },
    [flags]
  )
}

export const useIsFlagEnabled = (flag: string) => {
  const isFlagEnabled = useFlags()
  return isFlagEnabled(flag)
}

export const useIsFlagDisabled = (flag: string) => {
  const hasFlag = useIsFlagEnabled(flag)
  return !hasFlag
}
