import { QueryClient } from '@tanstack/react-query'

import { isAxiosError } from '@/api/client'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      throwOnError: (error) => {
        if (isAxiosError(error)) {
          return (
            (error.code && ['ERR_NETWORK'].includes(error.code)) ||
            (typeof error.status !== 'undefined' && error.status >= 500)
          )
        }
        return true
      },
    },
  },
})
