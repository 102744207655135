import { lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  LoaderFunction,
  Navigate,
  createBrowserRouter,
  useRouteError,
} from 'react-router-dom'

import { ErrorPage, RequirePermission } from '@primeit/react-pages'

import * as Sentry from '@sentry/react'
import { ZodError } from 'zod'

import { getCollaborator } from '@/api'
import { getDashboard } from '@/api/analytics'
import { isAxiosError } from '@/api/client'
import { getReportById, getReportConfig } from '@/api/reports/service'
import { getSettings } from '@/api/settings/service'
import { Permission } from '@/shared/enums'

const HistoryPage = lazy(() =>
  import('@/pages/Dashboard/Components/CollaboratorForm/HistoryPage').then(
    ({ HistoryPage }) => ({ default: HistoryPage })
  )
)
const MainLayout = lazy(() => import('@/components/Layouts/Main'))

const ReportsFormPage = lazy(() =>
  import('@/pages/Reports/ReportsFormPage').then((m) => ({
    default: m.ReportsFormPage,
  }))
)

const ReportsPage = lazy(() =>
  import('@/pages/Reports/ReportsPage').then((m) => ({
    default: m.ReportsPage,
  }))
)

const CollaboratorEditForm = lazy(() =>
  import('@/pages/Dashboard/Components').then((m) => ({
    default: m.CollaboratorEditForm,
  }))
)

const AddCareerPlanPage = lazy(() =>
  import('@/pages/CareerPlan/CareerPlanManagement').then((m) => ({
    default: m.AddCareerPlanPage,
  }))
)

const Dashboard = lazy(() => import('@/pages/Dashboard'))

const Analytics = lazy(() => import('@/pages/Analytics'))

const InactiveCollabModal = lazy(
  () => import('@/components/InactiveCollabModal')
)

const CareerPlanEmployeesPage = lazy(() =>
  import(
    '@/pages/CareerPlan/CareerPlanEmployees/CareerPlanEmployees.page'
  ).then((m) => ({
    default: m.CareerPlanEmployeesPage,
  }))
)
const CareerPlanManagementPage = lazy(() =>
  import(
    '@/pages/CareerPlan/CareerPlanManagement/CareerPlanManagement.page'
  ).then((m) => ({
    default: m.CareerPlanManagementPage,
  }))
)

const FollowUpModalPage = lazy(() =>
  import('@/pages/Dashboard/Components/FollowUpModal/FollowUpModalPage').then(
    ({ FollowUpModalPage }) => ({ default: FollowUpModalPage })
  )
)

const collaboratorLoader: LoaderFunction<{ collaboratorId: string }> = async ({
  params: { collaboratorId },
}) => {
  if (!collaboratorId) throw new Response('Not Found', { status: 404 })
  return getCollaborator(collaboratorId)
}

const settingsLoader = () => {
  return getSettings()
}

const CustomErrorPage = () => {
  const error = useRouteError() as Error | undefined
  const { t } = useTranslation('error_pages')

  useEffect(() => {
    if (import.meta.env.PROD) {
      Sentry.captureException(error)
    }
  }, [error])

  const status: number | string =
    isAxiosError(error) && error.response?.status
      ? error.response.status
      : error instanceof ZodError
        ? 'ZOD'
        : error && 'status' in error && typeof error.status === 'number'
          ? error.status
          : -1

  const message: string = t(`status.${status}`, t('status.500'), {})

  return (
    <ErrorPage label={t('title', { status })} message={message} error={error} />
  )
}

export const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <CustomErrorPage />,
    element: <MainLayout />,
    id: 'settings',
    loader: settingsLoader,
    children: [
      {
        path: '/',
        element: (
          <RequirePermission permissions={[Permission.EmployeesRead]}>
            <Dashboard />
          </RequirePermission>
        ),
        children: [
          {
            path: 'collaborators',
            children: [
              {
                path: 'create',
                element: (
                  <RequirePermission permissions={[Permission.EmployeesWrite]}>
                    <CollaboratorEditForm mode={'create'} />
                  </RequirePermission>
                ),
              },
              {
                path: ':collaboratorId',
                loader: collaboratorLoader,
                id: 'collaborator',
                children: [
                  {
                    index: true,
                    element: <CollaboratorEditForm mode={'view'} />,
                  },
                  {
                    path: 'edit',
                    element: (
                      <RequirePermission
                        permissions={[Permission.EmployeesWrite]}
                      >
                        <CollaboratorEditForm mode={'edit'} />
                      </RequirePermission>
                    ),
                  },
                  {
                    path: 'timeline',
                    element: (
                      <RequirePermission
                        permissions={[Permission.EmployeesReadHistory]}
                      >
                        <HistoryPage />
                      </RequirePermission>
                    ),
                  },
                  {
                    path: 'follow-up',
                    element: (
                      <RequirePermission
                        permissions={[Permission.EmployeesReadFollowUp]}
                      >
                        <FollowUpModalPage />,
                      </RequirePermission>
                    ),
                  },
                  {
                    path: 'inactivate',
                    element: (
                      <RequirePermission
                        permissions={[Permission.EmployeesInactivate]}
                      >
                        <InactiveCollabModal />
                      </RequirePermission>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'analytics',
        children: [
          {
            index: true,
            loader: () => {
              return getDashboard('index')
            },
            element: (
              <RequirePermission permissions={[Permission.AnalyticsView]}>
                <Analytics />
              </RequirePermission>
            ),
          },
          {
            path: ':slug',
            loader: ({ params }) => {
              return getDashboard(String(params.slug))
            },
            element: (
              <RequirePermission permissions={[Permission.AnalyticsView]}>
                <Analytics />
              </RequirePermission>
            ),
          },
        ],
      },
      {
        path: 'reports',
        children: [
          {
            index: true,
            element: (
              <RequirePermission permissions={[Permission.ReportsRead]}>
                <ReportsPage />
              </RequirePermission>
            ),
          },
          {
            path: 'create',
            loader: async () => {
              const config = await getReportConfig()
              return { config }
            },
            element: (
              <RequirePermission permissions={[Permission.ReportsWrite]}>
                <ReportsFormPage />
              </RequirePermission>
            ),
          },
          {
            path: ':id',
            loader: async ({ params }) => {
              const config = await getReportConfig()
              const report = await getReportById(String(params.id))
              return { config, report }
            },
            element: (
              <RequirePermission permissions={[Permission.ReportsWrite]}>
                <ReportsFormPage />
              </RequirePermission>
            ),
          },
        ],
      },
      {
        path: 'career-plan',
        children: [
          { index: true, element: <Navigate to={'/career-plan/management'} /> },
          {
            path: 'management',
            element: (
              <RequirePermission permissions={['career-plan:read']}>
                <CareerPlanManagementPage />
              </RequirePermission>
            ),
            children: [{ path: 'create', element: <AddCareerPlanPage /> }],
          },
          {
            path: 'collaborators',
            element: (
              <RequirePermission permissions={['career-plan:read:employees']}>
                <CareerPlanEmployeesPage />
              </RequirePermission>
            ),
          },
        ],
      },
    ],
  },
])
