import { primeApi } from '@primeit/react-auth'

import { AxiosError, isAxiosError } from 'axios'

const options = {
  apiUrl: import.meta.env.VITE_API_URL,
  ssoUrl: import.meta.env.VITE_SSO_URL,
  clientId: import.meta.env.VITE_SSO_CLIENT_ID,
}

const instances = primeApi(options)

export const apiClient = instances.apiClient
export const utils = instances.utils

export const isRequestError = (error: unknown) => {
  if (isAxiosError(error)) return error as AxiosError
  return null
}

export { isAxiosError }
